var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "h-screen flex w-full bg-img vx-row no-gutter items-center justify-center",
    },
    [
      _c(
        "div",
        {
          staticClass: "vx-col sm:w-4/5 md:w-1/2 lg:w-2/5 xl:w-1/4 sm:m-0 m-4",
        },
        [
          _c("vx-card", [
            _c(
              "div",
              {
                staticClass: "full-page-bg-color",
                attrs: { slot: "no-body" },
                slot: "no-body",
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "vx-row no-gutter justify-center items-center",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "vx-col xs:w-full sm:w-full md:w-full lg:w-full d-theme-dark-bg",
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "p-8 login-tabs-container" },
                          [
                            _c("div", { staticClass: "vx-card__title mb-4" }, [
                              _c("h4", { staticClass: "mb-4" }, [
                                _vm._v("Setup Two-Factor Authentication"),
                              ]),
                            ]),
                            _c("div", { staticClass: "vx-col w-full mb-4" }, [
                              _c("p", [
                                _vm._v(
                                  "Your company requires you to setup two-factor authentication. Use Google Authenticator or any authenticator app to scan this:"
                                ),
                              ]),
                            ]),
                            _c("img", {
                              staticStyle: { margin: "auto" },
                              attrs: { src: _vm.twoFactorDataUrl },
                            }),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "vx-col w-full flex justify-center",
                              },
                              [
                                _c("p", { staticClass: "mt-5" }, [
                                  _vm._v("Or enter the key manually: "),
                                ]),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "vx-col w-full flex justify-center",
                              },
                              [
                                _c("p", [
                                  _vm._v(_vm._s(_vm.formattedTwoFactorDataKey)),
                                ]),
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "vx-col w-full mt-3" },
                              [
                                _c("vs-input", {
                                  staticClass: "w-full mt-5",
                                  attrs: {
                                    name: "validationcode",
                                    type: "number",
                                    "label-placeholder":
                                      "Enter verfication code",
                                    placeholder: "Verification code",
                                  },
                                  model: {
                                    value: _vm.twoFactorInputCode,
                                    callback: function ($$v) {
                                      _vm.twoFactorInputCode = $$v
                                    },
                                    expression: "twoFactorInputCode",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm.showInvalidCodeMsg
                              ? _c(
                                  "div",
                                  { staticClass: "vx-col w-full mt-2" },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "text-danger text-sm" },
                                      [_vm._v("Invalid verification code.")]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _c(
                              "vs-button",
                              {
                                staticClass: "ml-auto mt-6",
                                attrs: { color: "danger", type: "flat" },
                                on: { click: _vm.handleCancel },
                              },
                              [_vm._v("Cancel")]
                            ),
                            _c(
                              "vs-button",
                              {
                                staticClass: "float-right ml-auto mt-6",
                                on: { click: _vm.handleSubmit },
                              },
                              [_vm._v("Submit")]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]
                ),
              ]
            ),
          ]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }